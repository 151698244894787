import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import CartIcon from "../../component/common/Carticon";
import { Modal } from "antd";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  Tab,
  TextField,
  Popper,
  Input,
} from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";

import PitchIcon from "../../assets/images/Pitch-icon.png";
import IceBathIcon from "../../assets/images/ice_bath.png";
import TenisIcon from "../../assets/images/tenis.png";
import FootballIcon from "../../assets/images/Football.png";
import BackButton from "../../assets/images/backBtn.svg";
import LeftButton from "../../assets/images/left-button.png";
import RightButton from "../../assets/images/right-button.png";

import Navbar from "../../component/common/NavbarProfile";
import LoadingBackdrop from "../../component/common/Loader";

import {
  createCustomer,
  createMaintanenceBooking,
  getAlreaduBookedPitches,
  getCountrytime,
  getCustomerlist,
  getHolidaylist,
  getPitchData,
} from "../../handlers/product/api";
import {
  getProducts,
  getProductsBasedOnFilter,
} from "../../handlers/common/api";
import { sampleSize } from "lodash";
registerLocale("en-GB", enGB);

const PitchSelectionTable = () => {
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [selectedPitchName, setSelectedPitchName] = useState(null);
  const [selectedPitchIcon, setSelectedPitchIcon] = useState(null);
  const [venueData, setVenueData] = useState(null);
  const [bookingOptions, setBookingOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("day");
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectOpenProduct, setSelectOpenProduct] = useState(false);
  const [selectOpenPitch, setSelectOpenPitch] = useState(false);
  const [selectOpenCustomer, setSelectOpenCustomer] = useState(false);
  const [pitche, setPitches] = useState([]);
  const [selectOpenpitches, setselectOpenpitches] = useState(false);
  const [sortedpitche, setsortedpitche] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedDisplaypitche, setselectedDisplaypitche] =
    useState("All Pitches");
  const [selectedDisplayCustomer, setselectedDisplayCustomer] = useState();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCreateUserPopup, setShowCreateUserPopup] = useState(false);
  const [bookedPitches, setbookedPitches] = useState([]);
  const [pitchWeekAvailability, setPitchWeekAvailability] = useState([]);
  const [selectedBookingOption, setSelectedBookingOption] = useState("");
  const [popupInfo, setPopupInfo] = useState({
    pitch: null,
    time: null,
    date: null,
  });
  const [cartItems, setCartItems] = useState([]);
  const [startPitchIndex, setStartPitchIndex] = useState(0);
  const [visiblePitches, setVisiblePitches] = useState();
  const [holidayList, setHolidayList] = useState();
  const [newTimeWithslots, setNewTimeWithslots] = useState();
  const [newTimeWithslotsWeek, setNewTimeWithslotsWeek] = useState();
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState();
  const [futuredays, setFuturedays] = useState();
  const [singaporeTime, setSingaporeTime] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [venuedatabooking, setvenuedatabooking] = useState();
  const [defaultname, setdefaultname] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [runonce, setRunonce] = useState(0);
  const [userInfo, setUserInfo] = useState({
    name: "",
    lastName: "",
    email: "",
    contactNumber: "",
  });

  const pitchesPerPage = 7; // number of pitch in slide bar
  const blocksPerPitch = 23; // number of time slot

  const navigate = useNavigate();

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const endtoDate = new Date(currentDate);
  endtoDate.setDate(currentDate.getDate() + 6);

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endtoDate);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [samplepitch, setSamplePitch] = useState(null);
  const [tempstate, settempstate] = useState("");

  const { venuenamefromurl, event } = useParams();

  const fetchServicesDetails = async (time, weekview, date) => {
    try {
      const times = time.split("-");
      const startTime = times[0].trim();
      const endTime = times[1].trim();

      const startDateTime = new Date("1970-01-01 " + startTime);
      const endDateTime = new Date("1970-01-01 " + endTime);

      function calculateDuration(time) {
        const times = time.split("-");
        const startTime = times[0].trim();
        const endTime = times[1].trim();

        // Convert time to 24-hour format for easier calculation
        const convertTo24HourFormat = (time) => {
          let [hours, minutesPeriod] = time.split(":");
          let [minutes, period] = minutesPeriod.split(" ");

          hours = parseInt(hours, 10); // Convert to number for proper comparison
          minutes = parseInt(minutes, 10); // Convert to number for proper comparison

          if (period === "PM" && hours !== 12) {
            hours += 12;
          } else if (period === "AM" && hours === 12) {
            hours = 0;
          }

          // Zero-padding for hours and minutes
          hours = hours.toString().padStart(2, "0");
          minutes = minutes.toString().padStart(2, "0");

          return `${hours}:${minutes}`;
        };

        const startTime24 = convertTo24HourFormat(startTime);
        const endTime24 = convertTo24HourFormat(endTime);

        const startDateTime = new Date(`1970-01-01T${startTime24}:00`);
        let endDateTime = new Date(`1970-01-01T${endTime24}:00`);

        if (endDateTime <= startDateTime) {
          endDateTime.setDate(endDateTime.getDate() + 1);
        }

        // Calculate the duration in minutes
        const duration = (endDateTime - startDateTime) / (1000 * 60);
        return duration;
      }
      // Calculate the duration in minutes
      const duration = (endDateTime - startDateTime) / (1000 * 60);

      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let dayIndex;
      let isHolidayStatus;
      if (weekview && date) {
        dayIndex = date.getDay();
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        isHolidayStatus = isHoliday(newDate);
      } else {
        dayIndex = selectedDate.getDay();
        isHolidayStatus = isHoliday(selectedDate);
      }
      const day = weekdays[dayIndex];

      console.log(selectedPitchName, "selectedPitchName");

      const options = {
        method: "POST",
        body: JSON.stringify({
          item_group: "Services",
          booking_for: selectedPitch,
          slot_start_time: convertTimeFormat(startTime),
          slot_end_time: convertTimeFormat(endTime),
          day_name: day,
          slot_duration_in_minutes: calculateDuration(time),
          is_holiday: isHolidayStatus,
          // is_internal: 0,
          for_birthday: selectedPitchName.birthday_event,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(`${getProductsBasedOnFilter}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            value: optionList[i].name,
            label: optionList[i].name + " - " + optionList[i].price + "/hr",
            image: optionList[i].image,
            price: optionList[i].price,
          });
        }
      }
      const sortedArr = arr.slice().sort((a, b) => b.price - a.price);
      setServiceItems(sortedArr);
      setSelectedServiceOptions(sortedArr[0]);

      // let selecteditem = sortedArr[0];
      // let payload = {
      //   item_code: sortedArr[0].value,
      //   rate: selecteditem.price,
      // };
      // localStorage.setItem("Product_Service", JSON.stringify(payload));
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  function isHoliday(date) {
    let formattedSelectedDate = date.toISOString().split("T")[0];

    if (holidayList) {
      for (let holiday of holidayList) {
        if (holiday.holiday_date === formattedSelectedDate) {
          return true;
        }
      }
    }

    return false;
  }

  function convertTimeFormat(inputTime) {
    // Parse the input time
    const [time, period] = inputTime.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format
    if (period.toUpperCase() === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    } else if (period.toUpperCase() === "AM" && hours === "12") {
      hours = "00";
    }

    // Ensure minutes is two digits
    minutes = minutes.padStart(2, "0");

    // Format the output as HH:MM:SS
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}:00`;
    return formattedTime;
  }

  const handleBack = () => {
    // Use navigate(-1) to go back to the previous page
    navigate(-1);
  };

  function formatDate(date) {
    if (!date) {
      return ""; // Return an empty string if date is null or undefined
    }

    // Check if date is already a Date object
    if (!(date instanceof Date)) {
      // Attempt to create a Date object from the input
      date = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string if date is invalid
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[monthIndex]} ${year}`;
  }

  const prevWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - 7);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (newStartDate >= today) {
      setStartDate(newStartDate);
      const newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() - 7);
      setEndDate(newEndDate);
    }

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 10);

    return () => clearTimeout(timeoutId);
  };

  const nextWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 7);
    setStartDate(newStartDate);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 7);
    setEndDate(newEndDate);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const clearDate = () => {
    setSelectedDate(null);
  };

  // const pitches = ["Pitch 1", "Pitch 2", "Pitch 3", "Pitch 4", "Pitch"];
  // const colors = ["#F88747", "#9547F8", "#FF7AFA", "#635954", "#476EF8"]; // Colors for each pitch
  // const coloring = ["#fee7db", "#eadbfe", "#ffc7fd", "#8c7e77", "#91a8fb"];

  // function dimColor(hexColor, percent = 15) {
  //   // Remove the '#' character from the hex color
  //   hexColor = hexColor.slice(1);

  //   // Parse the hexadecimal color into RGB components
  //   let red = parseInt(hexColor.substring(0, 2), 16);
  //   let green = parseInt(hexColor.substring(2, 4), 16);
  //   let blue = parseInt(hexColor.substring(4, 6), 16);

  //   // Calculate the dimming factor
  //   let factor = 1 - percent / 100;

  //   // Dim each RGB component
  //   red = Math.round(red * factor);
  //   green = Math.round(green * factor);
  //   blue = Math.round(blue * factor);

  //   // Convert the dimmed RGB values back to hexadecimal format
  //   red = red.toString(16).padStart(2, "0");
  //   green = green.toString(16).padStart(2, "0");
  //   blue = blue.toString(16).padStart(2, "0");

  //   // Combine the dimmed RGB values into a new hexadecimal color
  //   return `#${red}${green}${blue}`;
  // }

  const getTiming = (blockIndex) => {
    const startTime = (blockIndex % 24) + 1;
    const endTime = (startTime + 1) % 24 || 12; // For 24:00, display as 12:00 AM
    const startSuffix = startTime >= 12 ? "PM" : "AM";
    const endSuffix = endTime >= 12 ? "PM" : "AM";
    const formattedStartTime = startTime > 12 ? startTime - 12 : startTime;
    const formattedEndTime = endTime > 12 ? endTime - 12 : endTime;
    return `${formattedStartTime}:00 ${startSuffix}-${formattedEndTime}:00 ${endSuffix}`;
  };

  const fetchPitchData = async () => {
    try {
      const venuedata = JSON.parse(localStorage.getItem("venueData"));
      const res = await getPitchData(venuedata.data.name);
      const daysofweek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const pitchWeekAvailability = {}; // Object to store availability for each day

      const arr = res.data.data.venue_availability_time;
      const maxDate = new Date();
      maxDate.setDate(
        maxDate.getDate() + res.data.data.days_availability_for_calendar_view
      );
      setFuturedays(maxDate);
      for (let i = 0; i < arr.length; i++) {
        const day = arr[i].day; // Get the day from the API response

        if (!pitchWeekAvailability[day]) {
          pitchWeekAvailability[day] = []; // Initialize array if not already present
        }

        // Push availability object to the array
        pitchWeekAvailability[day].push({
          start_time: arr[i].start_time,
          end_time: arr[i].end_time,
        });
      }
      setPitchWeekAvailability(pitchWeekAvailability);
    } catch (error) {
      console.log(error);
    }
  };

  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    if (!timeToCheck) {
      return false;
    }
    if (availableRangeEnd.startsWith("23:59")) {
      availableRangeEnd = "24:00:00";
    }
    // Helper function to convert time in "HH:MM:SS" format to minutes since midnight
    const convert24HourToMinutes = (time) => {
      let [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    };

    // Helper function to convert time in "HH:MM AM/PM" format to minutes since midnight
    const convert12HourToMinutes = (time) => {
      let [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    };

    // Convert available range start and end to minutes since midnight
    const availableStart = convert24HourToMinutes(availableRangeStart);
    const availableEnd = convert24HourToMinutes(availableRangeEnd);

    // Extract and convert start and end times from the time range input to minutes since midnight
    const [startTime, endTime] = timeToCheck.split("-").map((t) => t.trim());
    const startMinutes = convert12HourToMinutes(startTime);
    const endMinutes = convert12HourToMinutes(endTime);

    // Handle case where end time is before start time (crossing midnight)

    const adjustedEndMinutes =
      endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

    // Check if the start and end times fall within the available range
    const fitsInRange =
      startMinutes >= availableStart && adjustedEndMinutes <= availableEnd;

    return fitsInRange;
  }

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  const generateTimeSlots = ({
    date,
    start_time,
    end_time,
    slot_time,
    slots_basis_of,
  }) => {
    // Parse the input times
    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return { hours, minutes, seconds };
    };

    // Format the time in HH:mm AM/PM format
    const formatTime = (dateObj) => {
      const hours = dateObj.getHours() % 12 || 12;
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const period = dateObj.getHours() < 12 ? "AM" : "PM";
      return `${hours}:${minutes}${period}`;
    };

    // Calculate the time increment based on slots_basis_of
    const getIncrement = () => {
      const increment = { minutes: 0, hours: 0 };
      if (slots_basis_of === "Minutes") {
        increment.minutes = parseInt(slot_time, 10);
      } else if (slots_basis_of === "Hourly") {
        increment.hours = parseInt(slot_time, 10);
      }
      return increment;
    };

    const start = parseTime(start_time);
    const end = parseTime(end_time);
    const increment = getIncrement();

    let currentTime = new Date(date);
    currentTime.setHours(start.hours, start.minutes, start.seconds);

    const endTime = new Date(date);
    endTime.setHours(end.hours, end.minutes, end.seconds);

    const timeSlots = [];

    while (currentTime < endTime) {
      const slotStart = new Date(currentTime);
      currentTime.setMinutes(currentTime.getMinutes() + increment.minutes);
      currentTime.setHours(currentTime.getHours() + increment.hours);

      if (currentTime <= endTime) {
        const slotEnd = new Date(currentTime);
        timeSlots.push(`${formatTime(slotStart)}-${formatTime(slotEnd)}`);
      }
    }

    if (endTime.getHours() === 23 && endTime.getMinutes() === 59) {
      const slotStart = new Date(endTime);
      slotStart.setMinutes(slotStart.getMinutes() - increment.minutes + 1);

      // Adjust endTime to represent midnight
      endTime.setHours(0, 0, 0, 0);
      endTime.setDate(endTime.getDate() + 1); // move to the next day for 12:00 AM

      timeSlots.push(`${formatTime(slotStart)}-${formatTime(endTime)}`);
    }

    console.log(timeSlots);
    return timeSlots;
  };

  useEffect(() => {
    // Retrieve venueData from localStorage
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (defaultname) {
      const defaultCustomer = customers.find(
        (customer) => customer.name === defaultname
      );
      setSelectedCustomer(defaultCustomer || { name: defaultname });
    } else {
      setSelectedCustomer(null);
    }
  }, [defaultname, customers]);

  useEffect(() => {
    if (selectedTab === "day") {
      setVisiblePitches(
        pitche.slice(startPitchIndex, startPitchIndex + pitchesPerPage)
      );
    } else {
      setVisiblePitches(pitche);
    }
  }, [
    selectedTab,
    startPitchIndex,
    pitchesPerPage,
    pitche,
    selectedPitch,
    defaultname,
  ]);

  useEffect(() => {
    fetchData();
    fetchPitchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch booking options
      const bookingResponse = await axios.post(
        "https://devmindmedia.frappe.cloud/api/method/mindmedia.api.booking.api.get_booking_for",
        { venue: venueData.data.name, maintanance_event: 1 }
      );
      const bookingOptions = bookingResponse.data.data || [];
      setBookingOptions(bookingOptions);
      // Fetch pitches
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterAndGenerateSlots = (data, weekview = 0, date = null) => {
    // Helper function to format time in the required format
    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${minutes}${period}`;
    };

    // Helper function to generate 1-hour time slots for the entire day
    const generateFullDaySlots = () => {
      const slots = [];
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);

      for (let i = 0; i < 24; i++) {
        const startTime = new Date(startOfDay);
        startTime.setHours(i);

        const endTime = new Date(startTime);
        endTime.setHours(i + 1);

        slots.push(`${formatTime(startTime)}-${formatTime(endTime)}`);
      }

      return slots;
    };

    // Extract day of the week from the provided date or current date
    const getDayOfWeek = (date) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date ? new Date(date).getDay() : new Date().getDay();
      return daysOfWeek[dayIndex];
    };

    const selectedDay = getDayOfWeek(weekview ? date : selectedDate);

    // Filter by booking_for_name
    const filteredByPitchName = data.filter((item) => {
      // console.log(item.booking_for_name, samplepitch, "sample pitch item bug");
      return item.name === samplepitch;
    });

    if (filteredByPitchName.length === 0) {
      return [];
    }

    const isIceBath = filteredByPitchName[0].booking_for_name === "Ice Bath";
    if (isIceBath) {
      // Generate 15-minute slots for the entire day if booking is for "Ice Bath"
      return generateTimeSlots({
        date: selectedDate,
        start_time: "00:00:00",
        end_time: "23:59:59",
        slot_time: "15",
        slots_basis_of: "Minutes",
      });
    }

    // Filter by day of the week
    const filteredByDay = filteredByPitchName[0].slot.filter((slot) => {
      return slot.day === selectedDay;
    });

    if (filteredByDay.length === 0) {
      // Return 1-hour slots for the entire day if no slots match the selected day
      return generateFullDaySlots();
    }

    // Generate time slots for each matching slot
    return filteredByDay.flatMap((slotData) => {
      return generateTimeSlots(slotData);
    });
  };

  // Helper function to parse time string in HH:MM AM/PM format to Date object
  const parseTime = (time) => {
    const [timeStr, period] = time.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1, 3);
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(
      period === "PM" && hours !== 12 ? hours + 12 : hours % 12,
      minutes,
      0,
      0
    );
    return date;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  // Helper function to format Date object to HH:MM AM/PM format
  const formatTime = (date) => {
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = date.getHours() < 12 ? "AM" : "PM";
    return `${hours}:${minutes}${period}`;
  };

  // Helper function to add minutes to a Date object
  const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  // Function to find gaps in the time slots
  const findTimeGaps = (inputSlots) => {
    const parseTime = (time) => {
      const [hourMin, period] = time
        .match(/(\d{1,2}:\d{2})([AP]M)/)
        .slice(1, 3);
      let [hour, minute] = hourMin.split(":").map(Number);
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      const date = new Date();
      date.setHours(hour, minute, 0, 0);
      return date;
    };

    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${minutes}${period}`;
    };

    const parsedSlots = inputSlots.map((slot) => {
      const [start, end] = slot.split("-");
      return {
        start: parseTime(start),
        end: parseTime(end),
        type: "slot",
      };
    });

    parsedSlots.sort((a, b) => a.start - b.start);

    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(startOfDay);
    endOfDay.setDate(endOfDay.getDate() + 1);

    const gaps = [];
    let lastEnd = startOfDay;

    parsedSlots.forEach((slot) => {
      if (slot.start > lastEnd) {
        const isMidnightGap =
          lastEnd.getHours() === 0 &&
          lastEnd.getMinutes() === 0 &&
          lastEnd.getSeconds() === 0 &&
          slot.start.getHours() === 0 &&
          slot.start.getMinutes() === 0 &&
          slot.start.getSeconds() === 0 &&
          lastEnd.toDateString() !== slot.start.toDateString();

        if (!isMidnightGap) {
          gaps.push({ start: lastEnd, end: slot.start, type: "gap" });
        }
      }
      lastEnd = slot.end;
    });

    if (
      lastEnd < endOfDay &&
      !(lastEnd.getHours() === 23 && lastEnd.getMinutes() === 59)
    ) {
      gaps.push({ start: lastEnd, end: endOfDay, type: "gap" });
    }

    const combined = [...parsedSlots, ...gaps];
    combined.sort((a, b) => a.start - b.start);

    const combinedRanges = combined.map((entry) => ({
      range: `${formatTime(entry.start)}-${formatTime(entry.end)}`,
      type: entry.type,
    }));

    // Adjust for the case where the last slot ends at 11:59 PM
    for (let i = 0; i < combinedRanges.length; i++) {
      if (
        combinedRanges[i].range === "11:45PM-12:00AM" &&
        combinedRanges[i].type === "gap"
      ) {
        combinedRanges[i].type = "slot";
      }
    }

    return combinedRanges;
  };

  useEffect(() => {
    let data;
    let weekData = [];

    if (selectedTab === "day") {
      data = filterAndGenerateSlots(bookingOptions);
      const gapRanges = findTimeGaps(data).filter(
        (item) => item.range !== "12:00AM-12:00AM"
      );
      setNewTimeWithslots(gapRanges);
    } else {
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        const formattedDate = formatDateforGetAlreadyBookedPitches(currentDate);

        // Temporarily override the selectedDate for the function call

        const dayData = filterAndGenerateSlots(
          bookingOptions,
          1,
          formattedDate
        );
        weekData.push({ date: formattedDate, slots: dayData });

        // Restore the original selectedDate
      }

      // You can now process weekData if needed, e.g., finding time gaps for each day
      // For example:
      const gapRangesForWeek = weekData.map((day) => {
        return {
          date: day.date,
          gaps: findTimeGaps(day.slots).filter(
            (item) => item.range !== "12:00AM-12:00AM"
          ),
        };
      });

      setNewTimeWithslotsWeek(gapRangesForWeek);
    }
  }, [bookingOptions, selectedDate, startDate, selectedTab]);

  function formatDateforGetAlreadyBookedPitches(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getNextDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day
    return currentDate.toISOString().split("T")[0]; // Convert to ISO date string (YYYY-MM-DD)
  }

  function doesNameAppearInPitches(selectedName) {
    // Iterate over the array of objects
    for (let i = 0; i < bookingOptions?.length; i++) {
      // Check if the current object has a matching "get_pitches_from_this_booking_for" value

      if (
        bookingOptions[i].get_pitches_from_this_booking_for === selectedName
      ) {
        return true; // Return true if a match is found
      }
    }
    return false; // Return false if no matches are found
  }

  const fetchAlreadyBookedPitches = async (selectedPitch) => {
    try {
      let payload = {};
      if (selectedTab === "day") {
        payload = {
          venue: venueData.data.name,
          // booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(selectedDate),
          end_date: getNextDate(
            formatDateforGetAlreadyBookedPitches(selectedDate)
          ),
        };
      } else {
        payload = {
          venue: venueData.data.name,
          // booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(startDate),
          end_date: formatDateforGetAlreadyBookedPitches(endDate),
        };
      }

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      // if (
      //   selectedPitch.toLowerCase().includes("8a") ||
      //   selectedPitch.toLowerCase().includes("birthday") ||
      //   selectedPitch.toLowerCase().includes("footb")
      // ) {
      //   delete payload["booking_for"];
      // }

      let booking_for_object = bookingOptions?.filter(
        (item) => item.name === selectedPitch
      );

      if (
        booking_for_object[0]?.get_pitches_from_this_booking_for ||
        doesNameAppearInPitches(booking_for_object[0]?.name) ||
        booking_for_object[0]?.consecutive_pitch
      ) {
        delete payload["booking_for"];
      }

      const res = await getAlreaduBookedPitches(formData);
      setbookedPitches(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getHolidays = async (selectedPitch) => {
    try {
      if (venueData) {
        let payload = { booking_for: venueData?.booking_for };

        const res = await getHolidaylist(payload);
        setHolidayList(res?.data?.data?.holidays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSingaporeTimeAsDateObject = () => {
    // Get the current date and time in Singapore as a string
    let singaporeTimeString = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Singapore",
    });

    // Split the date and time parts
    let [datePart, timePart] = singaporeTimeString.split(", ");

    // Further split the date and time into components
    let [month, day, year] = datePart.split("/");
    let [hours, minutes, seconds] = timePart.split(/[: ]/).map(Number);
    let period = timePart.split(" ")[1];

    // Adjust hours for AM/PM
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Create a new Date object in the local time zone with the Singapore time components
    let singaporeDate = new Date(year, month - 1, day, hours, minutes, seconds);

    return singaporeDate;
  };

  async function getCurrentTimeInSingapore() {
    try {
      const response = await getCountrytime({
        time_zone: "Asia/Singapore",
      });

      if (response.data.message.status_code !== 200) {
        throw new Error(
          `Error fetching time for Singapore: ${response.statusText}`
        );
      }

      const data = response.data.data;
      const dateString = data.current_time; // "29-07-2024 15:09:05"

      // Parse the datetime string using luxon
      const singaporeTime = DateTime.fromFormat(
        dateString,
        "dd-MM-yyyy HH:mm:ss",
        { zone: "Asia/Singapore" }
      );
      // console.log(singaporeTime);
      setSingaporeTime(singaporeTime);
      return singaporeTime;
    } catch (error) {
      console.error("Error fetching time:", error);
      return null;
    }
  }

  useEffect(() => {
    getCurrentTimeInSingapore();
  }, []);

  useEffect(() => {
    // console.log("Current Singapore Time:", singaporeTime);
  }, [singaporeTime]);

  const parseTimeToSingapore = (timeStr, referenceDate) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return referenceDate.set({ hour: hours, minute: minutes, second: 0 });
  };

  const isSlotDisabledByTodayTime = (
    slotTime,
    pitch,
    isWeekView = false,
    date = null
  ) => {
    if (!singaporeTime) {
      // console.error("Singapore time is not set yet");
      return false;
    }

    // console.log(slotTime, pitch, "sample input");
    // console.log(singaporeTime);

    // Use the fetched Singapore time
    let now = singaporeTime;
    let currentDate = isWeekView
      ? DateTime.fromJSDate(date).setZone("Asia/Singapore")
      : selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone("Asia/Singapore")
      : now;

    // If pitch is provided, add the pitch hours to the current time
    if (pitch) {
      const pitchHours = parseInt(pitch, 10);
      now = now.plus({ hours: pitchHours });
    }
    // console.log(now);

    const [startTime, endTime] = slotTime.split("-").map((time) => time.trim());

    // console.log(startTime, currentDate);
    const slotStartTime = parseTimeToSingapore(startTime, currentDate);

    // Compare slotStartTime with the adjusted now time
    // console.log(slotStartTime);
    // console.log(slotStartTime.toString());
    // console.log(now.toString());

    return slotStartTime < now;
  };

  const getCustomer = async () => {
    try {
      const res = await getCustomerlist();

      let c = res?.data?.data;
      let c2 = [
        { name: "Select", mobile_no: "Select", disabled: true },
        ...c,
        { name: "Create New Customer", mobile_no: "Create New Customer" },
      ];

      setCustomers(() => {
        if (c2.length) {
          return c2;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const filterOptions = (options, state) => {
    return options.filter((option) => {
      // console.log(option);
      if (option.name === "Create New Customer") {
        return true;
      }
      return (
        state.inputValue.length >= 3 &&
        option.mobile_no &&
        option.mobile_no.toLowerCase().includes(state.inputValue.toLowerCase())
      );
    });
  };

  useEffect(() => {
    getHolidays();
    getCustomer();
  }, [venueData]);

  useEffect(() => {
    if (selectedPitch) {
      fetchAlreadyBookedPitches(selectedPitch);
    }
  }, [selectedPitch, selectedDate, startDate, selectedTab, endDate]);

  useEffect(() => {
    const fetchData2 = async () => {
      if (selectedPitch && venueData) {
        const fields = encodeURIComponent(
          '["name","pitch_name","booking_allowed_after_hrs"]'
        );
        const venueFilter = encodeURIComponent(
          `["venue", "=", "${venueData.data.name}"]`
        );

        let booking_for_Filter = encodeURIComponent(
          `["booking_for", "=", "${selectedPitch}"]`
        );
        const stringAfterFirstHyphen = selectedPitch
          .split("-")
          .slice(1)
          .join("-");

        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === selectedPitch
        );

        let temp = booking_for_object[0]?.get_pitches_from_this_booking_for
          ? booking_for_object[0]?.get_pitches_from_this_booking_for
          : selectedPitch;

        booking_for_Filter = encodeURIComponent(
          `["booking_for", "=", "${temp}"]`
        );

        // if (
        //   booking_for_object[0].consecutive_pitch ||
        //   stringAfterFirstHyphen.toLowerCase().includes("birthday")
        // ) {
        //   const findFootball = bookingOptions.filter((item) => {
        //     console.log(item.booking_for_name.toLowerCase());
        //     return item.booking_for_name.toLowerCase().includes("footb");
        //   });

        //   if (findFootball) {
        //     booking_for_Filter = encodeURIComponent(
        //       `["booking_for", "=", "${findFootball[0]?.name}"]`
        //     );
        //   }
        // }
        let filters;
        console.log(booking_for_object);
        if (booking_for_object[0]?.for_maintenance) {
          filters = `[${venueFilter}]`;
        } else {
          filters = `[${venueFilter},${booking_for_Filter}]`;
        }

        const limit_page_length = encodeURIComponent("1000");
        // const fields = encodeURIComponent('["name"]');
        // const venueFilter = encodeURIComponent(
        //   `["venue", "=", "${venueData.data.name}"]`
        // );

        // const filters = `[${venueFilter}]`;
        // const limit_page_length = encodeURIComponent("1000");

        const url = `https://devmindmedia.frappe.cloud/api/resource/Pitches?fields=${fields}&filters=${filters}&limit_page_length=${limit_page_length}`;
        if (booking_for_object[0]) {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            const fetchedPitches = data.data || [];
            setPitches(fetchedPitches);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
    };
    fetchData2();
  }, [selectedPitch, venueData, bookingOptions]);

  useEffect(() => {
    fetchData();
  }, [venueData]);

  const handlePitchChange = (pitch) => {
    setSelectedPitch(pitch);
    setSelectOpen(true);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleselectmodel = () => {
    setCartItems([]);
    setIsModalVisible(false);
    setSelectedPitch(tempstate);
    // setvenuedatabooking(tempstate);

    const parts = tempstate.split("-");
    const stringAfterHyphen = parts.slice(1).join("-");
    let booking_for_object = bookingOptions?.filter(
      (item) => item.name === tempstate
    );

    setSelectedPitchName(booking_for_object[0]);

    // setSelectedPitchName(stringAfterHyphen);
    setSamplePitch(tempstate);
    setSelectOpen(false);
    // fetchData();
  };

  const handleSelectOpenProduct = () => {
    setSelectOpenProduct(!selectOpenProduct);
  };
  const handleSelectOpenPitches = () => {
    setselectOpenpitches(!selectOpenpitches);
  };

  const handleSelectOpenPitch = () => {
    setSelectOpenPitch(!selectOpenPitch);
  };

  const handleSelectOpenCustomer = () => {
    setSelectOpenCustomer(!selectOpenCustomer);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    handleSelectChange(event);
    setSelectedPitch(event);
  }, [event]);

  useEffect(() => {
    console.log(selectedPitchName);
  }, [selectedPitchName]);

  useEffect(() => {
    let booking_for_object = bookingOptions?.filter(
      (item) => item.name === event
    );
    if (runonce === 0) {
      console.log(selectedPitchName);
      setSelectedPitchName(booking_for_object[0]);
    }
    if (booking_for_object[0]) {
      setRunonce(1);
    }
  }, [bookingOptions]);

  const handleSelectChange = (event) => {
    if (cartItems.length > 0) {
      if (event?.target?.value) {
        settempstate(event?.target.value);
      }
      console.log(event);
      setIsModalVisible(true);
    } else {
      let event2;
      if (event?.target?.value) {
        event2 = event.target.value;
      } else {
        event2 = event;
      }

      if (event2 !== selectedPitch) {
        // Clear the cart and update the selected pitch
        if (selectedPitch) {
          setCartItems([]);
          localStorage.removeItem("cartItems");
        }
        setSelectedPitch(event2);
        // setvenuedatabooking(event2);
        const parts = event2.split("-");
        const stringAfterHyphen = parts.slice(1).join("-");

        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === event2
        );
        setSelectedPitchName(booking_for_object[0]);

        // setSelectedPitchName(stringAfterHyphen);
        setSamplePitch(event2);
        setSelectOpen(false); // Close the dropdown after selecting an item
        // fetchData();
      } else {
        // If the newly selected pitch is the same as the current pitch, just update the selected pitch
        setSelectedPitch(event2);
        const parts = event2.split("-");
        const stringAfterHyphen = parts.slice(1).join("-");
        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === event2
        );
        setSelectedPitchName(booking_for_object[0]);

        setSamplePitch(event2);
        setSelectOpen(false); // Close the dropdown after selecting an item
        // fetchData();
      }
      setSelectedBookingOption(event2);
    }
  };

  const handlePitchSelectChange = (event) => {
    setselectedDisplaypitche(event.target.value);
  };

  const handleCustomerSelectChange = (event, value) => {
    setselectedDisplayCustomer(value.name);
    if (value.name !== "Create New Customer" && value.name !== "Select") {
      localStorage.setItem("customer", value.name);
    }
    // console.log(event.target.value, event, value);
    if (value.name === "Create New Customer") {
      setShowCreateUserPopup(true);
      localStorage.setItem("customer", "");
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      handleClose();
      const payload = {
        customer_detail: {
          email: userInfo.email,
          mobile_no: `+65${userInfo.contactNumber}`,
          first_name: userInfo.name,
          last_name: userInfo.lastName,
        },
      };

      let fullName = userInfo.name + " " + userInfo.lastName;
      const res = await createCustomer(payload);
      // console.log("sample", res.data.message.data.name);
      setdefaultname(res?.data?.message?.data?.name);
      if (res.data.message.status_code === 409) {
        toast.warning("User Already Exist");
        setShowCreateUserPopup(false);
        setdefaultname(null);
      } else if (res.data.message.status_code === 200) {
        toast.success("User Created Successfully");
        setselectedDisplayCustomer("Select");
        // localStorage.setItem("customer", "");
        localStorage.setItem("customer", res.data.message.data.name);
        setShowCreateUserPopup(false);
        getCustomer();
      } else {
        toast.warning("Failed to create User");
      }
    } catch (error) {
      console.log(error);
      setShowCreateUserPopup(false);
    }
  };

  const handlePitchSelectChange2 = () => {
    if (selectedDisplaypitche === "All Pitches") {
      if (selectedTab === "day") {
        setVisiblePitches(
          pitche.slice(startPitchIndex, startPitchIndex + pitchesPerPage)
        );
      } else {
        setVisiblePitches(pitche);
      }
    } else {
      setVisiblePitches([{ name: selectedDisplaypitche }]);
    }
  };

  const handlePitchSelectChange3 = () => {
    setselectedDisplaypitche("All Pitches");
  };

  useEffect(() => {
    handlePitchSelectChange3();
  }, [selectedPitch]);
  useEffect(() => {
    handlePitchSelectChange2();
  }, [
    startPitchIndex,
    selectedDisplaypitche,
    selectedTab,
    pitche,
    selectedPitch,
  ]);

  useEffect(() => {
    setVenueData((prevData) => ({ ...prevData, booking_for: selectedPitch }));
    // to be unused after next update can be deleted
    // if (
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "football" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "basketball" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "footboll"
    // ) {
    //   setSelectedPitchIcon(FootballIcon);
    // } else if (
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "ice bath" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "icebath" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "ice_bath"
    // ) {
    //   setSelectedPitchIcon(IceBathIcon);
    // } else if (
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "tenis" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "badminton" ||
    //   selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "pickleball"
    // ) {
    //   setSelectedPitchIcon(TenisIcon);
    // } else {
    //   setSelectedPitchIcon(PitchIcon);
    // }
    // // new update
  }, [selectedPitch]);
  function parseTimeRange(timeRange) {
    const [startTime, endTime] = timeRange.split("-").map((time) => {
      const [timeString, modifier] = time.trim().split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);
      if (modifier === "PM" && hours !== 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`;
    });
    return { startTime, endTime };
  }
  function filterBookedPitches(bookedPitches, startTime, endTime) {
    return bookedPitches.filter((booking) => {
      // console.log(
      //   booking.starts_on === startTime && booking.ends_on === endTime
      // );
      return booking.starts_on === startTime && booking.ends_on === endTime;
    });
  }

  useEffect(() => {
    const icon = bookingOptions.filter((item) => item.name === selectedPitch);
    setSelectedPitchIcon(icon[0]?.icon_image ? icon[0]?.icon_image : PitchIcon);
  }, [selectedPitch, bookingOptions]);

  useEffect(() => {
    if (venueData) {
      localStorage.setItem("venueData", JSON.stringify(venueData));
    }
  }, [venueData]);

  const handleBoxClick = (pitch, time, weekview = false, date = undefined) => {
    const isSlotBooked = cartItems.some((bookedSlot) => {
      return (
        bookedSlot.name === pitch.name &&
        bookedSlot.time === time &&
        bookedSlot.date ===
          (weekview === true ? formatDate(date) : formatDate(selectedDate))
      );
    });

    if (isSlotBooked) {
      toast.warn("This slot is Already Booked");
    } else {
      // If slot is not booked, proceed with handling the click as before
      fetchServicesDetails(time, weekview, date).then(() => {
        setShowPopup(true);
        const { startTime, endTime } = parseTimeRange(time);
        let formattedSelectedDate = selectedDate.toISOString().split("T")[0];
        let startDateTime = `${formattedSelectedDate} ${startTime}`;
        let endDateTime = `${formattedSelectedDate} ${endTime}`;
        // console.log("Formatted Start DateTime:", startDateTime);
        // console.log("Formatted End DateTime:", endDateTime);

        // Filter booked pitches
        const filteredBookings = filterBookedPitches(
          bookedPitches,
          startDateTime,
          endDateTime
        );

        let filteredPitches = pitche.filter((pitch) => {
          return !filteredBookings.some(
            (filteredPitch) => filteredPitch.custom_pitch === pitch.name
          );
        });

        if (selectedPitchName?.consecutive_pitch) {
          // Main Part-----------------------------------------------
          let bookedPitchesArray = [];

          for (let i = 0; i < pitche.length; i = i + 3) {
            const pitch1 = pitche[i];
            const pitch2 = i + 1 < pitche.length ? pitche[i + 1] : null;
            const pitch3 = i + 2 < pitche.length ? pitche[i + 2] : null;

            if (
              filteredBookings.some(
                (booked) => booked.custom_pitch === pitch1?.name
              ) ||
              (pitch2 &&
                filteredBookings.some(
                  (booked) => booked.custom_pitch === pitch2.name
                )) ||
              (pitch3 &&
                filteredBookings.some(
                  (booked) => booked.custom_pitch === pitch3.name
                ))
            ) {
              console.log("continue");
              continue;
            } else {
              console.log("here this issue 1");
              bookedPitchesArray.push(pitch1);
            }
          }
          //--------------------------------------------------------------------------
          setsortedpitche(bookedPitchesArray);

          setPopupInfo({
            pitch: pitch || bookedPitchesArray[0],
            time,
            date:
              weekview === true ? formatDate(date) : formatDate(selectedDate),
          });
          console.log("bookedpithes array is sett", bookedPitchesArray);
        } else {
          setsortedpitche(filteredPitches);
          //  setsortedpitche(specialPitches)

          setPopupInfo({
            pitch: pitch || filteredPitches[0],
            time,
            date:
              weekview === true ? formatDate(date) : formatDate(selectedDate),
          });
        }
      });
    }
  };

  function convertTo24HourFormatweek(timeRange) {
    // Split the time range into start and end times
    const [startTime, endTime] = timeRange.split("-");

    // Convert each time to 24-hour format
    const startTime24 = to24Hour(startTime);
    const endTime24 = to24Hour(endTime);

    // Return the converted time range
    return `${startTime24}-${endTime24}`;
  }

  function to24Hour(time) {
    const [timePart, modifier] = time
      .match(/(\d{1,2}:\d{2})(AM|PM)/i)
      .slice(1, 3);
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier.toUpperCase() === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }

    // Format hours to ensure two digits
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function getMinutesInRange(timeRange) {
    const [startTime, endTime] = timeRange.split("-");

    // Helper function to convert time to Date object
    function parseTime(time) {
      const [timePart, period] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      return new Date(1970, 0, 1, hours, minutes); // Use a fixed date
    }

    const startDate = parseTime(startTime);
    let endDate = parseTime(endTime);

    // If endDate is before startDate, adjust the date to the next day
    if (endDate <= startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    // Calculate the difference in minutes
    const diffInMilliseconds = endDate - startDate;
    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    return diffInMinutes;
  }

  const calculateTimeLeft = useCallback(() => {
    const endTime = localStorage.getItem("countdownEndTime");
    if (!endTime) {
      return "not there";
    }
    const now = new Date().getTime();
    const timeRemaining = Math.max((endTime - now) / 1000, 0);
    return timeRemaining;
  }, []);

  // Set up the initial end time or retrieve from local storage
  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");
    if (!savedEndTime) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime2", endTime);
    }
    setTimeLeft(calculateTimeLeft());
  }, [calculateTimeLeft]);

  // Update the countdown every second
  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        const timeRemaining = calculateTimeLeft();
        setTimeLeft(timeRemaining);
        if (timeRemaining === "not there") {
          return;
        }
        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem("countdownEndTime");
          // localStorage.removeItem("cartItems");
          setCartItems([]);

          setIsRunning(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRunning, calculateTimeLeft]);

  const startTimer = (restart = true) => {
    if (restart) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime2", endTime);
    }
    setTimeLeft(calculateTimeLeft());
    setIsRunning(true);
  };

  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");

    if (savedEndTime) {
      setTimeLeft(calculateTimeLeft());
      setIsRunning(true);
    }
  }, [calculateTimeLeft]);
  // Format the time left as MM:SS
  const formatTimeForCountDown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (
      storedCartItems?.length === 0 ||
      storedCartItems === undefined ||
      storedCartItems === null
    ) {
      localStorage.removeItem("countdownEndTime");
    } else {
      calculateTimeLeft();
    }
    if (cartItems.length > 0) {
      startTimer(false);
    }
  }, [cartItems]);

  const handleAdd = () => {
    // Check if there's an adjacent item in the cart with the same pitch and date
    // const adjacentItemIndex = cartItems.findIndex((item) => {
    //   // Check if the item has time defined
    //   if (!item.time) {
    //     return false;
    //   }

    //   const [startTime, endTime] = item.time.split("-");
    //   const [selectedStartTime, selectedEndTime] = popupInfo.time.split("-");

    //   // Check if the start time of the current item matches the end time of the selected item
    //   if (endTime === selectedStartTime) {
    //     return (
    //       item.pitch === popupInfo.pitch &&
    //       item.date === popupInfo.date &&
    //       endTime === selectedStartTime
    //     );
    //   }

    //   // Check if the end time of the current item matches the start time of the selected item
    //   if (startTime === selectedEndTime) {
    //     return (
    //       item.pitch === popupInfo.pitch &&
    //       item.date === popupInfo.date &&
    //       startTime === selectedEndTime
    //     );
    //   }

    //   return false;
    // });

    // if (adjacentItemIndex !== -1) {
    //   // If an adjacent item is found, update its time slot accordingly
    //   const adjacentItem = cartItems[adjacentItemIndex];
    //   const startTime = adjacentItem.time.split("-")[0];
    //   const endTime = popupInfo.time.split("-")[1];
    //   const updatedItem = {
    //     ...adjacentItem,
    //     time: `${startTime}-${endTime}`,
    //   };

    //   // Update the cart items array with the updated item
    //   const updatedCartItems = [...cartItems];
    //   updatedCartItems.splice(adjacentItemIndex, 1, updatedItem);
    //   updateCartItems(updatedCartItems);
    // } else {
    // If no adjacent item is found, add the new item to the cart as usual
    if (cartItems.length === 0) {
      startTimer();
    }

    const code = serviceItems.find(
      (item) => item.label === selectedServiceOptions
    );
    console.log(selectedPitchName);
    if (selectedPitchName?.consecutive_pitch) {
      console.log(popupInfo);
      const no = pitche.findIndex(
        (pitch) => pitch.name === popupInfo.pitch.name
      );
      console.log(pitche);
      console.log(no);
      if (
        no < pitche.length - 2 &&
        !isSlotSelectedInCart(pitche[no + 1], popupInfo.time) &&
        !isSlotSelectedInCart(pitche[no + 2], popupInfo.time)
      ) {
        const newItem = [
          {
            pitch: popupInfo.pitch.name,
            pitch_name: popupInfo.pitch.pitch_name,
            time: popupInfo.time,
            date: popupInfo.date,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            isnext: false,
            name: `${venueData.data.name} | ${popupInfo.pitch.pitch_name} | ${selectedPitchName.booking_for_name}`,
          },
          {
            pitch: pitche[no + 1].name,
            pitch_name: pitche[no + 1].pitch_name,
            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            name: `${venueData.data.name} | ${pitche[no + 1].pitch_name} | ${
              selectedPitchName.booking_for_name
            }`,
          },
          {
            pitch: pitche[no + 2].name,
            pitch_name: pitche[no + 2].pitch_name,
            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            name: `${venueData.data.name} | ${pitche[no + 2].pitch_name} | ${
              selectedPitchName.booking_for_name
            }`,
          },
        ];

        const newCartItems = [...cartItems, ...newItem];
        updateCartItems(newCartItems);
        // }
      } else {
        toast.error("8a pitch not available starting from this pitch");
      }
    } else {
      const newItem = [
        {
          pitch: popupInfo.pitch.name,
          pitch_name: popupInfo.pitch.pitch_name,
          time: popupInfo.time,
          date: popupInfo.date,
          minutes: getMinutesInRange(popupInfo.time),
          item_code: code ? code.value : selectedServiceOptions.value,
          rate: code ? code.price : selectedServiceOptions.price,
          item_category: "Services",
          name: `${venueData.data.name} | ${popupInfo.pitch.pitch_name} | ${selectedPitchName.booking_for_name}`,
        },
      ];

      const newCartItems = [...cartItems, ...newItem];
      updateCartItems(newCartItems);
    }

    setShowPopup(false);
    setPopupInfo({ pitch: null, time: null, date: null });
  };

  const removeFromCart = (name, time, date) => {
    if (selectedPitchName?.consecutive_pitch) {
      const no = pitche.findIndex((pitch) => pitch.name === name);

      let updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== pitche[no].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 1].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 2].name ||
          item.time !== time ||
          item.date !== date
      );

      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    } else {
      const updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== name || item.time !== time || item.date !== date
      );
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };

  const isSlotSelectedInCart = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return true;
    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(time, item.time);
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotAlreadyBooked = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return false;
    let temp = bookedPitches.some((item) => {
      const isSamePitch = item.custom_pitch === pitch.name;

      const isSameDate =
        formatDate(item.starts_on.split(" ")[0]) ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(
        time,
        formatTimeRange(item.starts_on, item.ends_on)
      );
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotDeleatable = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    //if (!pitch) return true;
    //if (!selectedPitchName.substring(0, 2).toLowerCase() === "8a") return true;

    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(time, item.time);
      const isdeletable = !item.isnext;
      return isSamePitch && isSameDate && isTimeOverlap && isdeletable;
    });

    return temp;
  };

  function formatTimeRange(startTime, endTime) {
    // Convert start time to 12-hour format
    let startParts = startTime.split(" ")[1].split(":");
    let startHour = parseInt(startParts[0]);
    let startMinutes = startParts[1];
    let startPeriod = startHour >= 12 ? "PM" : "AM";
    startHour = startHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Convert end time to 12-hour format
    let endParts = endTime.split(" ")[1].split(":");
    let endHour = parseInt(endParts[0]);
    let endMinutes = endParts[1];
    let endPeriod = endHour >= 12 ? "PM" : "AM";
    endHour = endHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Construct the formatted time range string
    let formattedStartTime = `${startHour}:${startMinutes} ${startPeriod}`;
    let formattedEndTime = `${endHour}:${endMinutes} ${endPeriod}`;
    let timeRange = `${formattedStartTime}-${formattedEndTime}`;

    return timeRange;
  }

  const converthhmmssTimeToAMPM = (timeString) => {
    // Parse time string into hours, minutes, and seconds
    const [hoursStr, minutesStr] = timeString
      .split(":")
      .map((str) => parseInt(str, 10));

    // Determine if it's AM or PM
    const period = hoursStr < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    const hours12 = hoursStr % 12 || 12; // Handle 12 as 12 for 12-hour format
    const minutes = minutesStr.toString().padStart(2, "0");

    // Format the time into HH:mm AM/PM format
    const time12 = `${hours12}:${minutes} ${period}`;

    return time12;
  };

  function convertTo24HourFormattest(time12Hour) {
    const [startTime, endTime] = time12Hour.split("-");

    function convertTime(time) {
      let [timePart, period] = time.split(" ");
      let [hour, minute] = timePart.split(":").map(Number);

      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      hour = hour < 10 ? `0${hour}` : hour;
      minute = minute < 10 ? `0${minute}` : minute;

      return `${hour}:${minute}`;
    }

    const startTime24 = convertTime(startTime);
    const endTime24 = convertTime(endTime);

    return `${startTime24}-${endTime24}`;
  }

  const addMinutesToTimeAndSave = (timeString, minutesToAdd) => {
    // Parse time string into hours, minutes, and seconds
    const [hoursStr, minutesStr, secondsStr] = timeString
      .split(":")
      .map((str) => parseInt(str, 10));

    // Convert to milliseconds
    const timeInMilliseconds =
      (hoursStr * 60 * 60 + minutesStr * 60 + secondsStr) * 1000;

    // Add minutes
    const newTimeInMilliseconds = timeInMilliseconds + minutesToAdd * 60 * 1000;

    // Create a new Date object with the updated time
    const newDate = new Date(newTimeInMilliseconds);

    // Format the time into HH:mm:ss format
    const hours = newDate.getHours().toString().padStart(2, "0");
    const minutes = newDate.getMinutes().toString().padStart(2, "0");
    const seconds = newDate.getSeconds().toString().padStart(2, "0");

    const newTime = `${hours}:${minutes}:${seconds}`;

    // Example usage of saving the new time
  };

  // Function to check if a given time is within the range of another time range
  const isTimeInRange = (time, range) => {
    if (!range) return false;
    // Parse the time values as hours, minutes, and meridiem (AM/PM)
    const timeMatch = time.match(/(\d+):(\d+) (AM|PM)/);

    if (timeMatch === null) {
      return false;
    }

    const [selectedStartHour, selectedStartMinute, selectedStartMeridiem] =
      timeMatch.slice(1);
    const [slotStartHour, slotStartMinute, slotStartMeridiem] = range
      .split("-")[0]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [slotEndHour, slotEndMinute, slotEndMeridiem] = range
      .split("-")[1]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    // Convert hours to 24-hour format if necessary
    const convertTo24Hour = (hour, meridiem) => {
      if (hour === "12") {
        return meridiem === "AM" ? 0 : 12;
      }
      return parseInt(hour) + (meridiem === "PM" ? 12 : 0);
    };

    const selectedHour = convertTo24Hour(
      selectedStartHour,
      selectedStartMeridiem
    );
    const slotStart = convertTo24Hour(slotStartHour, slotStartMeridiem);
    const slotEnd = convertTo24Hour(slotEndHour, slotEndMeridiem);

    // Convert minutes to integers
    const selectedMinute = parseInt(selectedStartMinute);
    const slotStartMinuteInt = parseInt(slotStartMinute);
    const slotEndMinuteInt = parseInt(slotEndMinute);

    // Calculate the total minutes for the selected time
    const selectedTotalMinutes = selectedHour * 60 + selectedMinute;

    // Check if the selected time falls within the range
    if (slotStart <= slotEnd) {
      return (
        selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
        selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt
      );
    } else {
      // If the range spans across AM/PM, consider it as two separate ranges
      return (
        (selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
          selectedTotalMinutes < 24 * 60) ||
        (selectedTotalMinutes >= 0 &&
          selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt)
      );
    }
  };

  const updateCartItems = (newCartItems) => {
    setCartItems(newCartItems);
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };

  useEffect(() => {
    // Retrieve cartItems from localStorage on component mount
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    setShowCreateUserPopup(false);
    setPopupInfo({ pitch: null, time: null, date: null });
    setselectedDisplayCustomer("Select");
  };
  function formatDateToDayMonth(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Get the day of the month
    const day = date.getDate();

    // Get the month name
    const month = date.toLocaleString("default", { month: "short" });

    // Return the formatted string
    return `${day} ${month}`;
  }

  const handlePrevButtonClick = () => {
    if (startPitchIndex > 0) {
      setLoading(true);
      setStartPitchIndex(startPitchIndex - 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };

  const handleNextButtonClick = () => {
    if (startPitchIndex + pitchesPerPage < pitche.length) {
      setLoading(true);

      setStartPitchIndex(startPitchIndex + 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };
  const formatTimeRangespace = (timeRange) => {
    if (timeRange) {
      const insertSpace = (time) => {
        return time.replace(/([AP]M)/, " $1");
      };

      const [start, end] = timeRange.split("-");
      const formattedStart = insertSpace(start);
      const formattedEnd = insertSpace(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };
  const formatTimeRangeView = (timeRange) => {
    if (timeRange) {
      const formatTime = (time) => {
        let [hours, minutes] = time.split(" ")[0].split(":");
        const period = time.split(" ")[1];

        if (period === "PM" && parseInt(hours) !== 12) {
          hours = (parseInt(hours) + 12).toString();
        } else if (period === "AM" && parseInt(hours) === 12) {
          hours = "00";
        }
        hours = hours.padStart(2, "0");
        minutes = minutes.padStart(2, "0");

        return `${hours}:${minutes}`;
      };

      const [start, end] = timeRange.split("-");

      const formattedStart = formatTime(start);
      const formattedEnd =
        formatTime(end) === "00:00" ? "24:00" : formatTime(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const renderContent = () => {
    const boxStyle = {
      width: "150px",
      height: "40px",
      // border: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "5px",
    };

    const headingBoxStyle = {
      ...boxStyle,
    };

    if (selectedTab === "day") {
      const slots = [];
      const formattedDate = selectedDate.toISOString().split("T")[0];

      // Check if the formatted date exists in the holiday list
      const isHoliday = holidayList?.some(
        (holiday) => holiday.holiday_date === formattedDate
      );
      return (
        <div className="flex mt-2 mb-12 pitches">
          <div className="mt-5 mr-5 pitch-day">
            {selectedPitch && (
              <div className="w-3/4">
                <div className="flex text-white">
                  <div className="flex-col timing">
                    <div className="flex justify-end">
                      <div
                        className="headingBoxStyle arw-left "
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handlePrevButtonClick}
                      >
                        {"<"}
                      </div>
                    </div>
                    {[...Array(newTimeWithslots?.length)].map(
                      (_, blockIndex) => {
                        return (
                          <div className="timings">
                            {formatTimeRangeView(
                              formatTimeRangespace(
                                newTimeWithslots[blockIndex]?.range
                              )
                            ).replace("-", " ")}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      {visiblePitches.map((pitch, pitchIndex) => (
                        <div key={pitchIndex} className="headingBoxStyle">
                          {pitch.pitch_name}
                        </div>
                      ))}
                      <div
                        key={">"}
                        className="headingBoxStyle arw-rght"
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handleNextButtonClick}
                      >
                        {">"}
                      </div>
                    </div>
                    <div>
                      {" "}
                      {[...Array(newTimeWithslots?.length)].map(
                        (_, blockIndex) => {
                          return (
                            <div key={blockIndex} className="flex">
                              {visiblePitches.map((pitch, pitchIndex) => {
                                const slot = {
                                  name: pitch.name,
                                  time: formatTimeRangespace(
                                    newTimeWithslots[blockIndex]?.range
                                  ),
                                  date: formatDate(selectedDate),
                                };
                                slots.push(slot);

                                // const isBooked = cartItems.some(
                                //   (bookedSlot) => {
                                //     return (
                                //       bookedSlot.name === pitch.name &&
                                //       bookedSlot.time ===
                                //         formatTimeRangespace(
                                //           newTimeWithslots[blockIndex].range
                                //         ) &&
                                //       bookedSlot.date ===
                                //         formatDate(selectedDate)
                                //     );
                                //   }
                                // );

                                const alreadyBooked = isSlotAlreadyBooked(
                                  pitch,
                                  formatTimeRangespace(
                                    newTimeWithslots[blockIndex].range
                                  )
                                );

                                const isPitchAvailableday =
                                  pitchWeekAvailability[
                                    getDayOfWeek(selectedDate)
                                  ];

                                const isDisabledbyTodayTime =
                                  isSlotDisabledByTodayTime(
                                    formatTimeRangespace(
                                      newTimeWithslots[blockIndex].range
                                    ),
                                    0
                                    // pitch.booking_allowed_after_hrs
                                  );

                                const isPitchAvailable =
                                  isDisabledbyTodayTime ||
                                  newTimeWithslots[blockIndex]?.type ===
                                    "gap" ||
                                  (isPitchAvailableday &&
                                    !isPitchAvailableday.some((slot) => {
                                      return checkIfTimeFitsInRangeoforDay(
                                        slot.start_time,
                                        slot.end_time,
                                        formatTimeRangespace(
                                          newTimeWithslots[blockIndex]?.range
                                        )
                                      );
                                    }));

                                const is8A =
                                  selectedPitchName?.consecutive_pitch;

                                return (
                                  <>
                                    <div
                                      key={`${pitchIndex}-${blockIndex}`}
                                      style={{
                                        backgroundColor: isSlotSelectedInCart(
                                          pitch,
                                          formatTimeRangespace(
                                            newTimeWithslots[blockIndex]?.range
                                          )
                                        )
                                          ? "green"
                                          : alreadyBooked || isPitchAvailable
                                          ? "lightgray"
                                          : "white",
                                        borderColor:
                                          alreadyBooked || isPitchAvailable
                                            ? "#8F8F8F"
                                            : "#48c14c",
                                        cursor: isSlotDeleatable(
                                          pitch,
                                          formatTimeRangespace(
                                            newTimeWithslots[blockIndex]?.range
                                          )
                                        )
                                          ? "pointer"
                                          : isSlotSelectedInCart(
                                              pitch,
                                              formatTimeRangespace(
                                                newTimeWithslots[blockIndex]
                                                  ?.range
                                              )
                                            ) ||
                                            alreadyBooked ||
                                            isPitchAvailable ||
                                            (is8A && pitchIndex % 3 !== 0)
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          alreadyBooked ||
                                          isPitchAvailable ||
                                          (is8A && pitchIndex % 3 !== 0)
                                        ) {
                                        } else if (
                                          isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            )
                                          ) &&
                                          isSlotDeleatable(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            )
                                          )
                                        ) {
                                          removeFromCart(
                                            pitch.name,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            ),
                                            formatDate(selectedDate)
                                          );
                                        } else if (
                                          !isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            )
                                          )
                                        ) {
                                          handleBoxClick(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            )
                                          );
                                        }
                                      }}
                                      className="pitch-box-style"
                                    >
                                      <img
                                        src={selectedPitchIcon}
                                        alt="Pitch icon"
                                        style={{
                                          filter: isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslots[blockIndex]
                                                ?.range
                                            )
                                          )
                                            ? "hue-rotate(180deg) brightness(200%)"
                                            : alreadyBooked || isPitchAvailable
                                            ? "brightness(90%) saturate(0%)"
                                            : "",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (selectedTab === "week") {
      const boxStyleweek = {
        width: "170px",
        height: "35px",
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "5px",
      };
      const boxStyleweeks = {
        width: "150px",
        height: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "left",
        margin: "5px",
      };

      return (
        <div className="flex ml-20 items-center mb-12 week-calender">
          <div className="week-internal">
            <div className="mb-4 text-w week-internal-section">
              <div className="flex text-white">
                <div className="flex-col time-col-week">
                  <div className="flex justify-end">
                    <div
                      className="headingBoxStyle arrow-left"
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={prevWeek}
                    >
                      {"<"}
                    </div>
                  </div>
                  {/* {[...Array(blocksPerPitch)].map((_, blockIndex) => {
                    return (
                      <div className="timings-week">
                        {getTiming(blockIndex).replace("-", " ")}
                      </div>
                    );
                  })} */}
                </div>
                <div>
                  <div className="flex">
                    {[...Array(7)].map((_, index) => (
                      <div
                        key={index}
                        style={{ ...headingBoxStyle, width: boxStyle.width }}
                        className="headingBoxStyle"
                      >
                        {formatDateToDayMonth(
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate() + index
                          )
                        )}
                      </div>
                    ))}
                    <div
                      key={">"}
                      className="headingBoxStyle arrow-right"
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={nextWeek}
                    >
                      {">"}
                    </div>
                  </div>
                  <div>
                    {[
                      ...Array(
                        newTimeWithslotsWeek?.reduce((maxLength, current) => {
                          return Math.max(maxLength, current.gaps.length);
                        }, 0)
                      ),
                    ].map((_, blockIndex) => (
                      <div key={blockIndex} className="flex  mb-4">
                        {/* Render blocks for each day of the week */}
                        {[...Array(7)].map((_, dayIndex) => {
                          const isPitchAvailableday =
                            pitchWeekAvailability[
                              getDayOfWeek(
                                formatDate(
                                  new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    startDate.getDate() + dayIndex
                                  )
                                )
                              )
                            ];
                          const formattedDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate() + dayIndex + 1
                          )
                            .toISOString()
                            .split("T")[0];
                          // Check if the formatted date exists in the holiday list
                          const isHoliday = holidayList?.some(
                            (holiday) => holiday.holiday_date === formattedDate
                          );

                          let isPitchAvailable =
                            newTimeWithslotsWeek?.[dayIndex]?.gaps?.[blockIndex]
                              ?.type === "gap" ||
                            (isPitchAvailableday &&
                              !isPitchAvailableday.some((slot) => {
                                return checkIfTimeFitsInRangeoforDay(
                                  slot.start_time,
                                  slot.end_time,
                                  formatTimeRangespace(
                                    newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                      blockIndex
                                    ]?.range
                                  )
                                );
                              }));
                          const is8A = selectedPitchName?.consecutive_pitch;

                          return (
                            <>
                              {!newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                blockIndex
                              ]?.range ? (
                                <div className="boxStyleWeeks empty-div flex-col mb-3 mt-3">
                                  No Slots <br></br> Available
                                </div>
                              ) : (
                                <div
                                  key={dayIndex}
                                  style={boxStyleweeks}
                                  className="boxStyleWeeks flex-col mb-3 mt-3"
                                >
                                  <div className="block  flex justify-center  w-full text-sm time-slot">
                                    {convertTo24HourFormatweek(
                                      newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                        blockIndex
                                      ]?.range
                                    )}
                                  </div>
                                  {/* Render pitches for each day */}
                                  {visiblePitches.map((pitch, pitchIndex) => {
                                    const alreadyBooked = isSlotAlreadyBooked(
                                      pitch,
                                      formatTimeRangespace(
                                        newTimeWithslotsWeek?.[dayIndex]
                                          ?.gaps?.[blockIndex]?.range
                                      ),
                                      true,
                                      new Date(
                                        startDate.getFullYear(),
                                        startDate.getMonth(),
                                        startDate.getDate() + dayIndex
                                      )
                                    );

                                    const isDisabledByTime =
                                      isSlotDisabledByTodayTime(
                                        formatTimeRangespace(
                                          newTimeWithslotsWeek?.[dayIndex]
                                            ?.gaps?.[blockIndex]?.range
                                        ),
                                        0,
                                        // pitch.booking_allowed_after_hrs,
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      );

                                    return (
                                      <div
                                        key={pitchIndex}
                                        className={`pitch-week-box-style mb-2`}
                                        style={{
                                          backgroundColor: isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "green"
                                            : alreadyBooked ||
                                              isPitchAvailable ||
                                              isDisabledByTime
                                            ? "lightgray"
                                            : "white",

                                          cursor: isSlotDeleatable(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "pointer"
                                            : isSlotSelectedInCart(
                                                pitch,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                true,
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              ) ||
                                              (is8A && pitchIndex % 3 !== 0)
                                            ? "not-allowed"
                                            : "pointer",
                                          border: isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "1px solid green"
                                            : alreadyBooked ||
                                              isPitchAvailable ||
                                              isDisabledByTime
                                            ? "1px solid #8F8F8F"
                                            : "",
                                          width: "auto",
                                        }}
                                      >
                                        {/* Checkbox */}
                                        <div
                                          className="sm:text-small flex"
                                          onClick={() => {
                                            if (is8A && pitchIndex % 3 !== 0) {
                                            } else if (
                                              !(
                                                alreadyBooked ||
                                                isPitchAvailable ||
                                                isDisabledByTime
                                              )
                                            ) {
                                              isSlotSelectedInCart(
                                                pitch,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                true,
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              ) &&
                                              isSlotDeleatable(
                                                pitch,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                true,
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              )
                                                ? removeFromCart(
                                                    pitch.name,
                                                    formatTimeRangespace(
                                                      newTimeWithslotsWeek?.[
                                                        dayIndex
                                                      ]?.gaps?.[blockIndex]
                                                        ?.range
                                                    ),
                                                    formatDate(
                                                      new Date(
                                                        startDate.getFullYear(),
                                                        startDate.getMonth(),
                                                        startDate.getDate() +
                                                          dayIndex
                                                      )
                                                    )
                                                  )
                                                : handleBoxClick(
                                                    pitch,
                                                    formatTimeRangespace(
                                                      newTimeWithslotsWeek?.[
                                                        dayIndex
                                                      ]?.gaps?.[blockIndex]
                                                        ?.range
                                                    ),
                                                    true,
                                                    new Date(
                                                      startDate.getFullYear(),
                                                      startDate.getMonth(),
                                                      startDate.getDate() +
                                                        dayIndex
                                                    )
                                                  );
                                            }
                                          }}
                                        >
                                          <div
                                            className="pitch-name-week"
                                            style={{
                                              color: isSlotSelectedInCart(
                                                pitch,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                true,
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              )
                                                ? "white"
                                                : alreadyBooked ||
                                                  isPitchAvailable ||
                                                  isDisabledByTime
                                                ? "#8F8F8F"
                                                : "green",
                                            }}
                                          >
                                            {pitch.pitch_name}
                                          </div>
                                          <img
                                            src={selectedPitchIcon}
                                            alt="Pitch icon"
                                            className="w-4 h-4"
                                            style={{
                                              filter: isSlotSelectedInCart(
                                                pitch,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                true,
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              )
                                                ? "hue-rotate(180deg) brightness(200%)"
                                                : alreadyBooked ||
                                                  isPitchAvailable ||
                                                  isDisabledByTime
                                                ? "brightness(90%) saturate(0%)"
                                                : "",
                                            }}
                                          />
                                        </div>
                                        {/* Pitch name */}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  if (!venueData) {
    return <div>Loading...</div>;
  }
  function convertTo24hrDateTime(dateString, timeRangeString) {
    // Parse the date string
    const dateParts = dateString.split(" ");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames.indexOf(dateParts[1]) + 1;
    const day = parseInt(dateParts[0], 10); // Parse the day as an integer
    const year = parseInt(dateParts[2], 10); // Parse the year as an integer

    // Parse the time range string
    const [startTime, endTime] = timeRangeString.split("-");

    // Convert start time to 24-hour format
    const [startHour, startMinute] = startTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const startDate = new Date(
      year,
      month - 1,
      day,
      (startHour % 12) + (startTime.includes("PM") ? 12 : 0),
      startMinute
    );
    const startDay = startDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const startYear = startDate.getFullYear();
    const startDateTimeString = `${startYear}-${startMonth}-${startDay} ${
      startDate.toTimeString().split(" ")[0]
    }`;

    // Convert end time to 24-hour format
    const [endHour, endMinute] = endTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const endDate = new Date(
      year,
      month - 1,
      day,
      (endHour % 12) + (endTime.includes("PM") ? 12 : 0),
      endMinute
    );
    const endDay = endDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const endYear = endDate.getFullYear();
    const endDateTimeString = `${endYear}-${endMonth}-${endDay} ${
      endDate.toTimeString().split(" ")[0]
    }`;

    return [startDateTimeString, endDateTimeString];
  }

  const handleBookNow = async () => {
    if (selectedPitchName.for_maintenance === 0) {
      const cus = localStorage.getItem("customer");
      if (cus) {
        if (venueData) {
          // Save movie data to localStorage before navigating
          localStorage.setItem("venueData", JSON.stringify(venueData));

          navigate(
            `/venue-listing-page/venue/${encodeURIComponent(
              venueData.data.name
            )}/booking-page/accessories`
          );
        }
      } else {
        toast.warning("Please Select a Customer");
      }
    } else {
      try {
        const payload = {
          maintanance_booking_data: {
            booking_for: venueData.booking_for,
            venue: venueData.data.name,
            pitches_slots: cartItems
              .filter((item) => item.item_category === "Services")
              .map((pitch) => ({
                pitch_name: pitch.pitch,
                slot_start_date_time: convertTo24hrDateTime(
                  pitch.date,
                  pitch.time
                )[0],
                slot_end_date_time: convertTo24hrDateTime(
                  pitch.date,
                  pitch.time
                )[1],
              })),
          },
        };

        const res = await createMaintanenceBooking(payload);
        if (
          res.data.message.message === "Maintanance Booking Successfully Placed"
        ) {
          toast.success("Maintanence Booking Placed");
          localStorage.removeItem("cartItems");
          navigate("/");

          // window.location.href =
          //   "https://devmindmedia.frappe.cloud/app/event?custom_maintenance_booking=1";
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleCartClick = () => {
    navigate("/viewcart");
  };

  function convertDateFormat(dateStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [day, month, year] = dateStr.split(" ");
    const monthNumber = monthNames.indexOf(month) + 1;
    const formattedMonth = monthNumber.toString().padStart(2, "0");
    const formattedDay = day.padStart(2, "0");
    const formattedDate = `${year}/${formattedMonth}/${formattedDay}`;

    return formattedDate;
  }

  return (
    <>
      <div className="book-section max-w-full py-4 flex justify-between items-center px-6 sm:px-20 min-h-full">
        <div className="flex-grow ">
          <div className="flex flex-row  justify-between mt-4 mx-4  ">
            <button
              onClick={handleBack}
              className="backBtn flex items-center space-x-2"
            >
              <img
                className="rounded-full nav-profile-pic"
                src={BackButton}
                alt="Back Button"
              />
              <span>Back</span>
            </button>
            <button
              onClick={handleCartClick}
              className="flex  lg:mx-20 rounded-full "
            >
              <CartIcon className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            </button>
          </div>
          <header>
            <div className="flex  items-center  mt-4">
              <h2 className="text-3xl mt-4 font-bold text-start ">
                {venueData?.data?.venue_name}
                <br /> {/*venue number */}
                <h6 className="relative text-sm hidden">
                  {venueData?.data?.address}
                </h6>
              </h2>
              <div className="mt-4 px-6">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 sm:px-2 rounded view-cart-button"
                  onClick={() => {
                    if (cartItems?.length > 0) {
                      handleBookNow();
                    } else {
                      toast.warn("No items in the cart");
                    }
                  }}
                >
                  Next ({cartItems.length})
                </button>
              </div>
            </div>
          </header>

          <div className="flex flex-wrap lg:space-x-10 py-4 ">
            <div className="text-4xl font-bold text-start booking-for">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-select-small-label" className="">
                  Booking for
                </InputLabel>
                <Select
                  key={selectedPitch}
                  labelId="demo-select-small-label"
                  id="demo-select-small btn-one"
                  label="Booking to"
                  placeholder="Booking for"
                  open={selectOpen}
                  onClose={handleSelectClose}
                  onOpen={handleSelectOpen}
                  onChange={handleSelectChange}
                  className="select-box"
                  value={selectedPitch} // Use value prop for default value
                >
                  {bookingOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.booking_for_name}
                    </MenuItem>
                  ))}
                </Select>

                {selectedBookingOption === "" && (
                  <span className="text-lg">Please select an option</span>
                )}
              </FormControl>
            </div>
            <div className="text-4xl font-bold text-start relative pitches-shown">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-input-small-label">Pitches</InputLabel>
                <Select
                  labelId="demo-select-small-label-pitches"
                  id="demo-select-small-pitches"
                  label="Pitches"
                  placeholder="Select"
                  defaultValue={"All Pitches"}
                  open={selectOpenPitch}
                  onClose={handleSelectOpenPitch}
                  onOpen={handleSelectOpenPitch}
                  onChange={handlePitchSelectChange}
                  className="select-box"
                >
                  {pitche.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                  <MenuItem key="All Pitches" value="All Pitches">
                    All Pitches
                  </MenuItem>
                </Select>
              </FormControl>
              {/* {timeLeft === "not there" || timeLeft === 0 || timeLeft === 900
              ? ""
              : formatTimeForCountDown(timeLeft)} */}
            </div>
            <div className="text-4xl font-bold text-start relative pitches-shown">
              <FormControl sx={{ m: 1, minWidth: 230 }}>
                <Autocomplete
                  id="customer-select"
                  options={customers}
                  getOptionLabel={(option) => option.name || ""}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setSelectedCustomer(newValue);
                    handleCustomerSelectChange(event, newValue);
                  }}
                  value={selectedCustomer}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  openOnFocus
                  onOpen={handleSelectOpenCustomer}
                  onClose={handleSelectOpenCustomer}
                  disableClearable
                  renderOption={(props, option) => (
                    <li {...props} disabled={option.disabled}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 8,
                        // inputMode: 'numeric',
                        // pattern: '[0-9]*',
                      }}
                    />
                  )}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      placement="bottom-start"
                      modifiers={[
                        {
                          name: "offset",
                          options: {},
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            altAxis: true,
                            rootBoundary: "viewport",
                          },
                        },
                      ]}
                      style={{ zIndex: 2000 }}
                    >
                      {props.children}
                    </Popper>
                  )}
                />
              </FormControl>
            </div>
          </div>
          {selectedPitch && (
            <div className="  font-bold text-start relative pitches-shown flex  date-tabs justify-start">
              {selectedTab === "day" ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select a date"
                  dateFormat="yyyy/MM/dd"
                  minDate={new Date()}
                  className="border text-center border-gray-300 mt-10 rounded-lg p-2 h-12 data-picker"
                />
              ) : (
                <div className="week-selector text-center ml-20 mt-10 rounded-lg p-2 h-12 data-picker-week">
                  <button onClick={prevWeek}>
                    <img src={LeftButton} alt="Left Navigation button" />
                  </button>
                  <h4 className="week-range">
                    {formatDateToDayMonth(startDate)} -{" "}
                    {formatDateToDayMonth(endDate)}
                  </h4>
                  <button onClick={nextWeek}>
                    <img src={RightButton} alt="Right Navigation button" />
                  </button>
                </div>
              )}

              {selectedDate && <button onClick={clearDate}></button>}
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                className="day-week-tab rounded-lg border border-gray-300 mt-10 ml-3 h-8 date-tabs mr-16"
              >
                <Tab
                  label="Day"
                  value="day"
                  className="rounded-lg day-pitch relative"
                  style={{ paddingRight: "1rem" }} // Add padding to the right to accommodate the divider
                >
                  <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-px h-3/5 bg-gray-300"></div>
                </Tab>
                <Tab
                  label="Week"
                  value="week"
                  className="rounded-lg pitch-week"
                />
              </Tabs>
            </div>
          )}
          {loading ? <LoadingBackdrop open={loading} /> : renderContent()}
          {selectedPitch && (
            <div className="flex  mb-12 day-week-tab">
              <button
                className="bg-red-500 hover:bg-red-700 text-white  font-bold py-2 px-4 sm:px-2 rounded  view-cart-button"
                onClick={() => {
                  if (cartItems.length > 0) {
                    handleBookNow();
                  } else {
                    toast.warn("No items in the cart");
                  }
                }}
              >
                Next ({cartItems.length})
              </button>
            </div>
          )}
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
              <div className="bg-white p-8 rounded-lg z-10 fly-cart">
                <h2 className="text-2xl font-bold mb-4">
                  Pitch you Selected:{popupInfo.pitch.pitch_name}
                </h2>
                <p className="mb-2">
                  Time: {convertTo24HourFormattest(popupInfo.time)}
                </p>
                <p className="mb-4">
                  Date: {convertDateFormat(popupInfo.date)}
                </p>

                {true && (
                  <div className="mt-8">
                    <Select
                      key={selectedPitch}
                      id="demo-select-small btn-one"
                      placeholder="Booking for"
                      open={selectOpenpitches}
                      onClose={handleSelectOpenPitches}
                      onOpen={handleSelectOpenPitches}
                      onChange={(event) => {
                        const selectedPitch = event.target.value;
                        console.log("value", selectedPitch);
                        console.log(sortedpitche);

                        const selectedPitchItem = sortedpitche.find((item) => {
                          return selectedPitch === item.name;
                        });

                        console.log(selectedPitchItem);

                        setPopupInfo({
                          pitch: selectedPitchItem,
                          time: popupInfo.time,
                          date: formatDate(popupInfo.date),
                        });

                        // setSelectedServiceOptions(value.target.value);

                        // let selecteditem = serviceItems.find(
                        //   (item) => item.label === value.target.value
                        // );
                        // let payload = {
                        //   item_code: value.target.value,
                        //   rate: selecteditem.price,
                        // };
                      }}
                      className="select-box mb-3"
                      value={popupInfo.pitch.name}
                      style={{ minWidth: "100px" }}
                      defaultValue={serviceItems[0]?.name} // Ensure the default value matches the type in `value` and `MenuItem`
                    >
                      {sortedpitche.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.pitch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {true && (
                  <div className="mt-8">
                    <Select
                      key={selectedPitch}
                      id="demo-select-small btn-one"
                      placeholder="Booking for"
                      open={selectOpenProduct}
                      onClose={handleSelectOpenProduct}
                      onOpen={handleSelectOpenProduct}
                      onChange={(value) => {
                        setSelectedServiceOptions(value.target.value);
                        let selecteditem = serviceItems.find(
                          (item) => item.label === value.target.value
                        );
                        let payload = {
                          item_code: value.target.value,
                          rate: selecteditem.price,
                        };
                        // localStorage.setItem(
                        //   "Product_Service",
                        //   JSON.stringify(payload)
                        // );
                      }}
                      className="select-box mb-3"
                      value={
                        selectedServiceOptions?.label
                          ? selectedServiceOptions?.label
                          : selectedServiceOptions
                      }
                      style={{ minWidth: "100px" }}
                      defaultValue={serviceItems[0]}
                    >
                      {serviceItems?.map((option) => (
                        <MenuItem key={option.label} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="flex justify-between">
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleAdd}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
          {showCreateUserPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
              <div className="bg-white p-8 rounded-lg z-10 fly-cart">
                <h2 className="text-2xl font-bold mb-4">Create New User</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700">First Name*</label>
                    <input
                      type="text"
                      name="name"
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      value={userInfo.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      value={userInfo.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Email*</label>
                    <input
                      type="email"
                      name="email"
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      value={userInfo.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      Contact Number*
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      value={userInfo.contactNumber}
                      onChange={handleChange}
                      required
                      maxLength={8}
                    />
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Add User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        <Modal
          title="Confirm Remove Item"
          visible={isModalVisible}
          onOk={handleselectmodel}
          onCancel={() => setIsModalVisible(false)}
          className="removeCartPopup"
        >
          <p>Are you sure you want to remove All item from the cart?</p>
        </Modal>
      </div>
    </>
  );
};

export default PitchSelectionTable;
