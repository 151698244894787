import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Divider, Row } from "antd";
import Navbar from "../../component/common/NavbarProfile";
import image from "../../assets/images/cardDetailsImage.png"; //set real venue image
import BackButton from "../../assets/images/backBtn.svg"; //set real venue image
import { venueListingPage } from "../../handlers/common/api";
import batIcon from "../../assets/images/bat-icon.png";
import Contact from "../../assets/images/iconamoon_profile-thin.png";
import Email from "../../assets/images/iconamoon_email-thin.png";
import Phone from "../../assets/images/ph_phone-thin.png";
import defaultPitch from "../../assets/images/pitch default.jpeg";
import NearMeIcon from "@mui/icons-material/NearMe";
import axios from "axios";
import CartIcon from "../../component/common/Carticon";

const VenueDetailsPage = () => {
  const { name } = useParams();
  const decodedName = decodeURIComponent(name.replace(/\+/g, " "));
  const formattedName = decodedName.replace(/%20/g, " ");
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUnderline, setShowUnderline] = useState(false);
  const [bookingOptions, setBookingOptions] = useState([]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await fetch(`${venueListingPage}/${formattedName}`);
        if (!response.ok) {
          throw new Error("Failed to fetch venue details");
        }
        const data = await response.json();
        setVenue(data);
        setShowUnderline(true);
      } catch (error) {
        console.error("Error fetching venue details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVenueDetails();
  }, [formattedName]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (venue?.data) {
          // Fetch booking options
          const bookingResponse = await axios.post(
            "https://devmindmedia.frappe.cloud/api/method/mindmedia.api.booking.api.get_booking_for",
            { venue: venue.data.name, maintanance_event: 1 }
          );
          let bookingOptions = bookingResponse.data.data || [];
          console.log(bookingOptions, bookingOptions.length);
          if (
            bookingOptions &&
            typeof bookingOptions === "object" &&
            !Array.isArray(bookingOptions) &&
            Object.keys(bookingOptions).length === 0
          ) {
            bookingOptions = [];
          }
          setBookingOptions(bookingOptions);
          console.log(bookingOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [venue]);

  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional for smooth scrolling
  });

  const handleBookNow = (event) => {
    if (venue) {
      // Save venue data to localStorage before navigating
      let copyvenue = venue;
      copyvenue.booking_for = event;
      copyvenue.data.booking_for = event;
      const previousVenueData = JSON.parse(localStorage.getItem("venueData"));
      console.log(previousVenueData, copyvenue);
      if (previousVenueData) {
        if (previousVenueData.booking_for !== copyvenue.booking_for) {
          console.log(previousVenueData.booking_for, copyvenue.booking_for);
          localStorage.removeItem("cartItems");
        }
      }

      localStorage.setItem("venueData", JSON.stringify(venue));
      navigate(
        `/venue-listing-page/venue/${encodeURIComponent(
          venue.data.name
        )}/booking-page/${event}`
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!venue) {
    return <div>Error: venue not found</div>;
  }

  const venueMoreInformationText =
    venue.data && venue.data.venue_more_information
      ? venue.data.venue_more_information.replace(/<\/?[^>]+(>|$)/g, "")
      : "";

  const insertLineBreaks = (text, breakAfter) => {
    let result = "";
    for (let i = 0; i < text.length; i++) {
      result += text[i];
      if ((i + 1) % breakAfter === 0) {
        result += "<br>";
      }
    }
    return result;
  };

  const extractTextWithSerialNumbers = (htmlString) => {
    if (!htmlString) return ""; // Check if htmlString is undefined or null
    const listItems = htmlString.match(/<li[^>]*>(.*?)<\/li>/g);
    if (!listItems) return ""; // Return empty string if no list items found
    const linesWithSerialNumbers = listItems?.map((item, index) => {
      const text = item.replace(/<\/?[^>]+(>|$)/g, "");
      return `${index + 1}. ${text}`;
    });
    return linesWithSerialNumbers.join("\n");
  };

  const venueDetailsTextWithSerialNumbers = extractTextWithSerialNumbers(
    venue.data.venue_details
  );

  const generateGoogleMapsLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  };

  const googleMapsLink = generateGoogleMapsLink(venue.data.address);
  const handleCartClick = () => {
    navigate("/viewcart");
  };

  return (
    <>
      <div>
        <header>
          <div className="flex flex-row  justify-between mt-4 mx-4  ">
            <button
              onClick={handleBack}
              className="backBtn flex items-center space-x-2"
            >
              <img
                className="rounded-full nav-profile-pic"
                src={BackButton}
                alt="Back Button"
              />
              <span>Back</span>
            </button>
            <button
              onClick={handleCartClick}
              className="flex  lg:mx-20 rounded-full "
            >
              <CartIcon className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            </button>
          </div>
          <div className="flex max-w-full justify-between px-6 lg:px-20 items-center header-section">
            <h1 className="relative py-6 venueDetailsHeading">
              {venue.data.venue_name}
              <br />
              <h6 className="relative text-sm">
                {venue.data.address}
                {/* {showUnderline && (
                  <div
                    className="absolute bg-black"
                    style={{
                      width: "600px",
                      height: "1px",
                      bottom: "-5px",
                      left: "0",
                    }}
                  ></div>
                )} */}
              </h6>
            </h1>
            <div>
              <a
                href={googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-red-700 flex flex-col justify-center items-center"
              >
                <NearMeIcon /> Venue Directions
              </a>
            </div>
            {/* <button
              onClick={handleBookNow}
              className="text-white px-4 rounded bookingButton hover:ease-in-out hover:opacity-80"
            >
              Book Now
            </button> */}
          </div>
        </header>
        <div className="flex flex-col max-w-full justify-between px-6 lg:px-20 ">
          <img
            src={`${venue.data.base_url}${venue.data.venue_image}`}
            alt="Venue"
            className="w-full rounded-xl object-cover"
            style={{ maxHeight: "560px" }}
            onError={(e) => {
              e.target.src = defaultPitch;
            }}
          />
          <div className="pt-8 venue-content">
            <h2>{venue.data.venue_name}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: venue.data.venue_details,
              }}
            />
          </div>
          <hr></hr>
          <div className="venue-info">
            <p
              dangerouslySetInnerHTML={{
                __html: insertLineBreaks(venueMoreInformationText, 150),
              }}
              className="venue-details"
            />

            <Row justify="start">
              {venue.data.venue_components?.map((component) => (
                <Col
                  key={component.name}
                  xs={{ span: 12 }}
                  sm={{ span: 6 }}
                  className="py-3 venue-cards"
                >
                  <div className="flex venue-boxes">
                    <img src={batIcon} alt="icon" className="" />
                    <div>
                      <h4>{component.component}</h4>
                      <p>{component.description}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <hr></hr>
            <div className="py-8 venue-content venue-contact">
              <h2>For Details Please Contact:</h2>
              <ul className="flex m-0 p-0 flex-col">
                <li>
                  <img src={Contact} alt="Contact" />
                  {venue.data.person_name}
                </li>
                <li>
                  <img src={Email} alt="Email" />
                  {venue.data.email}
                </li>
                <li>
                  <img src={Phone} alt="Phone" />
                  +65{venue.data.tel}
                </li>
              </ul>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start">
              <div className="book-text flex-1 text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-800 mb-4 lg:mb-0 sm:text-center lg:text-left">
                <h2 className="booking-avaiable">Booking Available</h2>
              </div>
            </div>
            <div className="booking-grid grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 p-0 mx-0">
              {console.log(bookingOptions)}
              {bookingOptions &&
                bookingOptions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-center mb-4 booking-btns wrap"
                    >
                      <span className="text-gray-700 mb-2 md:mb-0 text-center font-semibold item-booking whitespace">
                        {item.booking_for_name}
                      </span>
                      <button
                        onClick={() => {
                          handleBookNow(item.name);
                        }}
                        className="text-white  w-full sm:w-2/3 md:w-1/2 lg:w-[70%] max-w-md rounded-lg bookingButton hover:ease-in-out hover:opacity-80 bg-blue-500 whitespace"
                      >
                        Book Now
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueDetailsPage;
